import React from "react";
import Switch from "react-bootstrap/esm/Switch";
// import { Dashboard } from "./dashboard/dashboard";
import { NavigationBar } from "./navBar";
// import { Route } from "react-router";
import firebase from "firebase";
import { Redirect, Route } from "react-router-dom";
// import { UserList } from "./dashboard/userList";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PersonalDetails from "./personalDetailsForm";
import { UserList } from "./userList";

export function Home() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavigationBar></NavigationBar>
      <div className="mainContent">
        <Switch>
          {/* <Route path="/app" render={() => <Redirect to={"/app/createGuest"} />} /> */}
          {/* <Route path="/app/createGuest" component={Dashboard} /> */}
          <Route exact path="/app/list/25FF4AD" component={UserList} />
          <Route exact path="/app/parivarShibir" component={PersonalDetails} />
          
        </Switch>
      </div>
      {/* <Footer></Footer> */}
    </>
  );
}
