import firebase from "firebase";
let config = {
  apiKey: "AIzaSyDVmuIWQ8fvbD0l3Oz0SG397uI5CeLsCtk",
  authDomain: "ydsnz2023.firebaseapp.com",
  projectId: "ydsnz2023",
  storageBucket: "ydsnz2023.appspot.com",
  messagingSenderId: "775373317230",
  appId: "1:775373317230:web:ebcad61da3429581aa327f",
  measurementId: "G-CG1VXDY85T"
};

firebase.initializeApp(config);

const db = firebase.firestore();

class ParivarShibirDataService {
  getAll() {
    return db;
  }

  create() {
    return db.collection("parivarShibir").doc();
  }

  update(id: any) {
    return db.collection("parivarShibir").doc(id);
  }
}

export default new ParivarShibirDataService();
