import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import parivarShibirService from "./parivarShibir.service";
import { toast } from "react-toastify";

const PersonalDetails = () => {
    const defaultUser = {
        firstName: "",
        lastName: "",
        email: "",
        dob: null,
        streetAddress: "",
        city: "",
        pinCode: "",
        phoneNo: "",
        requiredTransport: "",
        arrivingBy: "",
        otherComment: "",
        accommodation: "",
        dietComment: "",
        feesQuantity: 0,
        totalFamilyMember: 0
    };
    const defaultFamilyObj = {
        userFirstName: "",
        userContactNo: "",
        userDob: null,

    };

    const defaultFlightObj = {
        arrivingDateTime: null,
        arrivingFlightNumber: "",
        departerDateTime: null,
        departerFlightNumber: ""
    };
    const [user, setUser] = useState(defaultUser);
    const [familyMemberDetails, setfamilyMemberDetails]: any[] = useState([]);
    const [flightDetails, setFlightDetails] = useState(defaultFlightObj);
    const [isDisable, setIsDisable] = useState(false);



    const handleChange = (value: any, key: any) => {
        setUser({
            ...user,
            [key]: value,
        });
    };

    const handleChangeGuest = (value: any, key: any, index: any) => {
        const obj1 = familyMemberDetails[index];
        familyMemberDetails[index] = Object.assign(obj1, { [key]: value });
        setfamilyMemberDetails([...familyMemberDetails]);
    };

    const handleChangeFlight = (value: any, key: any) => {
        setFlightDetails({
            ...flightDetails,
            [key]: value,
        });
    };

    const addNewRow = () => {
        let data = [];
        data.push(defaultFamilyObj);
        setfamilyMemberDetails([...familyMemberDetails, ...data]);
    };

    const getDivider = (width = "100%") => {
        return <div className="d-flex justify-content-center" style={{ width: "100%" }}>
            <div style={{ border: "1px solid #ededf1", width: width }}>
            </div>
        </div>
    }

    const deleteRow = (index: any) => {
        let data = [...familyMemberDetails];
        data.splice(index, 1);
        setfamilyMemberDetails([...data]);
    };

    const submitData = () => {
        const userData = {
            ...user,
            ...flightDetails,
            familyDetails: familyMemberDetails,
            createdDate: new Date()
        }
        // console.log(userData)
        parivarShibirService.create().set({
            userData
        }).then(res => {
            setUser(defaultUser)
            setFlightDetails(defaultFlightObj);
            setfamilyMemberDetails([defaultFamilyObj]);
            setIsDisable(false);
            window.scroll(0, 0)
            toast.success("Your details has been saved successfully..", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }).catch((err) => {
            console.log(err)
        })
    }

    return <>
        <div>
            <div className="container ">
                <div className="text-center">

                    <img src="../smurtiBanner.png" style={{ height: 128, width: 369 }}></img>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            submitData()
                            setIsDisable(true);

                        }}>
                            <div className="text-center py-3" >
                                <h6 style={{ lineHeight: "25px" }}>
                                    Hari Prabodham Parivar Shibir Queenstown March 2023 Registration Form
                                </h6>
                                <div style={{ fontSize: ".875em" }}>
                                    Event Date: March 24, 2023 to March 26, 2023
                                </div>
                            </div>
                            {getDivider()}
                            <div className="text-center py-3">
                                <h5>
                                    Shibir Details
                                </h5>
                                <div style={{ lineHeight: "25px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                    <div >24th March - <span> 4:00 PM to 8:00 PM - Smurti Session</span></div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>25th March - <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}><span> 9:00 AM to 1:00 PM - Session 1 </span><span> 5:00 PM to 9:00 PM - Session 2</span></div></div>
                                    <div >26th March - <span >9:00 AM to 1:00 PM - Session 3</span></div>
                                </div>

                            </div>
                            {getDivider()}

                            <div className="col-12 row text-left pt-3">
                                <div className="py-2 col-lg-3">
                                    <label>First Name*</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        onChange={(e) => handleChange(e.target.value, "firstName")}
                                        value={user.firstName}
                                    />
                                </div>
                                <div className="py-2 col-lg-3">
                                    <label>Last Name*</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        onChange={(e) => handleChange(e.target.value, "lastName")}
                                        value={user.lastName}
                                    />
                                </div>
                                <div className="py-2 col-lg-3">
                                    <label>Email*</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        onChange={(e) => handleChange(e.target.value, "email")}
                                        value={user.email}
                                    />
                                </div>

                                <div className="py-2 col-lg-3">
                                    <label>Date of Birth*</label>
                                    <ReactDatePicker required maxDate={new Date()} placeholderText="Date of Birth" selected={user.dob} dateFormat="dd/MM/yyyy" className="form-control" onChange={(date) => handleChange(date, "dob")} />
                                </div>

                                <div className="py-2 col-lg-3">
                                    <label>Phone No*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone No"
                                        required
                                        onChange={(e) => handleChange(e.target.value, "phoneNo")}
                                        value={user.phoneNo}
                                    />
                                </div>

                                {/* {getDivider("50%")}
                            <div className="py-2 col-lg-12">
                                <label>Address Details</label>
                            </div> */}
                                <div className="py-2"></div>
                                <div className="py-2 col-lg-3">
                                    <label>Street Address*</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Street Address"
                                        onChange={(e) => handleChange(e.target.value, "streetAddress")}
                                        value={user.streetAddress}
                                    />
                                </div>
                                <div className="py-2 col-lg-3 col-6">
                                    <label>City*</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        onChange={(e) => handleChange(e.target.value, "city")}
                                        value={user.city}
                                    />
                                </div>
                                <div className="py-2 col-lg-3 col-6">
                                    <label>Pin Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="PinCode"
                                        onChange={(e) => handleChange(e.target.value, "pinCode")}
                                        value={user.pinCode}
                                    />
                                </div>
                                <div className="py-2"></div>



                                <div className="py-2 col-lg-12">
                                    <label>
                                        Do you required transport in Queentown?* <br />
                                        <span style={{ fontSize: "13px" }}>From Airport to Accommodation and Accommodation to Airport only. Rest will be self arranged.</span>

                                    </label>
                                    <div className="d-flex d-flex align-items-center">
                                        <input required type="radio" name="requiredTransport" id="requiredTransport1" value={"Yes"} checked={user.requiredTransport === "Yes"} onChange={(e) => handleChange(e.target.value, "requiredTransport")} />
                                        <div className="m-2">
                                            Yes
                                        </div>
                                    </div>
                                    <div className="d-flex d-flex align-items-center">
                                        <input required type="radio" name="requiredTransport" id="requiredTransport2" value={"No"} checked={user.requiredTransport === "No"} onChange={(e) => handleChange(e.target.value, "requiredTransport")} />
                                        <div className="m-2">
                                            No
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2 col-lg-12">
                                    <label>How are you arriving to Shibir?*</label>
                                    <div className="d-flex d-flex align-items-center">
                                        <input required type="radio" name="arrivingBy" id="arrivingBy1" value={"ByCar"} checked={user.arrivingBy === "ByCar"} onChange={(e) => handleChange(e.target.value, "arrivingBy")} />
                                        <div className="m-2">
                                            By Car
                                        </div>
                                    </div>
                                    <div className="d-flex d-flex align-items-center">
                                        <input required type="radio" name="arrivingBy" id="arrivingBy2" value={"ByFlight"} checked={user.arrivingBy === "ByFlight"} onChange={(e) => handleChange(e.target.value, "arrivingBy")} />
                                        <div className="m-2">
                                            By Flight
                                        </div>
                                    </div>
                                    <div className="d-flex d-flex align-items-center">
                                        <input required type="radio" name="arrivingBy" id="arrivingBy3" value={"Other"} checked={user.arrivingBy === "Other"} onChange={(e) => handleChange(e.target.value, "arrivingBy")} />
                                        <div className="m-2">
                                            Other
                                        </div>
                                    </div>
                                    {user.arrivingBy === "Other" &&
                                        <div className="py-2 col-lg-3 col-12">
                                            <input
                                                required={user.arrivingBy === "Other" ? true : false}
                                                type="text"
                                                className="form-control"
                                                placeholder="Please type another option here."
                                                onChange={(e) => handleChange(e.target.value, "otherComment")}
                                                value={user.otherComment}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            {user.arrivingBy === "ByFlight" &&
                                <>
                                    {getDivider()}
                                    <div className="py-3">

                                        <div className="text-center pb-3">
                                            <h5>
                                                Flight Details
                                            </h5>
                                        </div>
                                        <div className="col-12 row text-left">

                                            <div className="py-2 col-lg-6">
                                                <label>Queenstown Arrival Date and Time*</label>
                                                <ReactDatePicker required minDate={new Date()} placeholderText="Arrival Date and Time" showTimeSelect dateFormat="dd/MM/yyyy h:mm aa" selected={flightDetails.arrivingDateTime} className="form-control" onChange={(date) => handleChangeFlight(date, "arrivingDateTime")} />
                                            </div>
                                            <div className="py-2 col-lg-6">
                                                <label>Flight No.*</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Flight No."
                                                    onChange={(e) => handleChangeFlight(e.target.value, "arrivingFlightNumber")}
                                                    value={flightDetails.arrivingFlightNumber}
                                                />
                                            </div>


                                            <div className="py-2 col-lg-6">
                                                <label>Queenstown Departure Date and Time</label>
                                                <ReactDatePicker minDate={flightDetails.arrivingDateTime} placeholderText="Departure Date and Time" showTimeSelect dateFormat="dd/MM/yyyy h:mm aa" selected={flightDetails.departerDateTime} className="form-control" onChange={(date) => handleChangeFlight(date, "departerDateTime")} />
                                            </div>
                                            <div className="py-2 col-lg-6">
                                                <label>Departure Flight No.</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Flight No."
                                                    onChange={(e) => handleChangeFlight(e.target.value, "departerFlightNumber")}
                                                    value={flightDetails.departerFlightNumber}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </>
                            }

                            {getDivider()}
                            <div className="py-3">
                                <div className="text-center pb-3">
                                    <h5>
                                        Family Details
                                    </h5>
                                </div>
                                <div className="col-12 row text-left">
                                    <div className="py-2 col-lg-6">
                                        <label>
                                            Please add details of each member of family attending Shibir including you*
                                        </label>
                                        <div className="form-floating">
                                            <select required className="form-select" id="floatingSelect" aria-label="Floating label select example" value={user.totalFamilyMember} onChange={(e) => {
                                                let selectedData: any = []
                                                Array.from(Array(parseInt(e.target.value)).keys()).forEach(res => {
                                                    selectedData.push({ ...defaultFamilyObj });
                                                })
                                                if (selectedData.length > 0) {
                                                    selectedData[0].userFirstName = user.firstName
                                                    selectedData[0].userContactNo = user.phoneNo
                                                    selectedData[0].userDob = user.dob
                                                }
                                                setfamilyMemberDetails([...selectedData]);
                                                handleChange(e.target.value, "totalFamilyMember")
                                                // console.log(e.target.value)
                                            }}>
                                                <option selected value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                            </select>
                                            <label htmlFor="floatingSelect">Family Member including you</label>
                                        </div>
                                    </div>
                                </div>
                                {user.totalFamilyMember ?
                                    <div className="py-2 col-lg-12 pb-4">
                                        <div className="py-3">
                                            Add Family Details here. (FIRST NAME - SURNAME ) | BIRTHDATE
                                        </div>

                                        {familyMemberDetails.map((res: any, index: number) => {
                                            return (
                                                <>
                                                    <div className="d-lg-flex p-3  table-row mb-3" style={{ gap: "10px" }}>
                                                        {index > 0 && (
                                                            <div
                                                                className="delete"
                                                                onClick={() => {
                                                                    deleteRow(index);
                                                                }}
                                                            >
                                                                <img src={"../delete.png"}></img>
                                                            </div>
                                                        )}

                                                        <div className="py-2 col-lg-3">
                                                            <label>First Name*</label>
                                                            <input
                                                                disabled={index === 0}
                                                                required
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                onChange={(e) =>
                                                                    handleChangeGuest(
                                                                        e.target.value,
                                                                        "userFirstName",
                                                                        index
                                                                    )
                                                                }
                                                                value={res.userFirstName}
                                                            />
                                                        </div>
                                                        <div className="py-2 col-lg-3">
                                                            <label>Phone No</label>
                                                            <input
                                                                disabled={index === 0}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Phone No"
                                                                onChange={(e) =>
                                                                    handleChangeGuest(
                                                                        e.target.value,
                                                                        "userContactNo",
                                                                        index
                                                                    )
                                                                }
                                                                value={res.userContactNo}
                                                            />
                                                        </div>
                                                        <div className="py-2 col-lg-3">
                                                            <label>Date of Birth*</label>
                                                            <ReactDatePicker
                                                                disabled={index === 0}
                                                                maxDate={new Date()} required placeholderText="Date of Birth"
                                                                dateFormat={"dd/MM/yyyy"}
                                                                className="form-control" selected={res.userDob} onChange={(date) => handleChangeGuest(
                                                                    date,
                                                                    "userDob",
                                                                    index
                                                                )} />
                                                        </div>
                                                    </div>
                                                    {index === familyMemberDetails.length - 1 ? (
                                                        <div className="d-flex justify-content-end">
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-secondary"
                                                                    // disabled={karyakarta.isSubmit}
                                                                    onClick={() => {
                                                                        addNewRow();
                                                                    }}
                                                                >
                                                                    Add New Member
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </>
                                            );
                                        })}

                                    </div> : null
                                }
                                <div className="col-lg-6">
                                    <label>Any Dietary Requirement Please add</label>
                                    <textarea className="form-control" value={user.dietComment} onChange={(e) => handleChange(e.target.value, "dietComment")}></textarea>
                                </div>
                            </div>

                            {getDivider()}
                            <div className="py-3 col-lg-12">
                                <label>   Do you need accommodation before or after shibir?</label>
                                <div className="d-flex d-flex align-items-center">
                                    <input type="radio" name="accommodation" id="accommodation1" value={"Yes"} checked={user.accommodation === "Yes"} onChange={(e) => handleChange(e.target.value, "accommodation")} />
                                    <div className="m-2">
                                        Yes
                                    </div>
                                </div>
                                <div className="d-flex d-flex align-items-center">
                                    <input type="radio" name="accommodation" id="accommodation2" value={"No"} checked={user.accommodation === "No"} onChange={(e) => handleChange(e.target.value, "accommodation")} />
                                    <div className="m-2">
                                        No
                                    </div>
                                </div>
                                {user.accommodation === "Yes" &&
                                    <div className="py-2">
                                        <div className="alert alert-primary" role="alert">
                                            Please contact Niravbhai savaliya personally. <a href="tel:+642040389108">(+642040389108)</a>
                                        </div>
                                    </div>
                                }
                            </div>

                            {getDivider()}
                            <div className="py-3">

                                <div className="text-center pb-3">
                                    <h5>
                                        Shibir Fees</h5>
                                </div>
                                <div className="pb-3 text-center">
                                    <h6>
                                        Bank Account Details for Shibir Fees
                                    </h6>
                                </div>


                                <div className="pb-3" style={{ lineHeight: "30px" }}>
                                    HariPrabodham Group Limited <br />
                                    <div>
                                        Account Number: <span style={{ fontWeight: "bold" }}>02-0184-0471674-000</span> <br />
                                    </div>
                                    <div>
                                        Particulars: <span style={{ fontWeight: "bold" }}>(Firstname) </span> <br />
                                    </div>
                                    <div>
                                        Code: <span style={{ fontWeight: "bold" }}>(Lastname)</span>  <br />
                                    </div>
                                    <div>
                                        Reference:<span style={{ fontWeight: "bold" }}> (Queenstown)</span>
                                    </div>
                                </div>
                                {getDivider()}

                                <div className="pt-3">
                                    {/* <div>
                                    Shibir Fees*
                                </div> */}
                                    <div>
                                        <div className="d-flex justify-content-between" style={{ fontWeight: "bolder" }}>
                                            <div>
                                                Shibir Fees Per Person
                                            </div>
                                            <div >
                                                $250.00 NZD
                                            </div>
                                        </div>
                                        <text style={{ fontSize: 12 }}>
                                            Includes: 2 Nights stay in Queenstown, Breakfast, Lunch & Dinner (Children below 10 no fees).
                                        </text>
                                        {/* <div className="py-3">
                                            <div>
                                                Quantity
                                            </div>

                                            <div className="py-2 col-lg-3">
                                                <select required className="form-select" id="floatingSelect" aria-label="Floating label select example" value={user.feesQuantity}
                                                    onChange={(e) => handleChange(e.target.value, "feesQuantity")}>
                                                    <option selected value="">Select Quantity</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* {getDivider()}

                                    <div className="pt-3 d-flex justify-content-end">
                                        <div>
                                            Total <span style={{ fontWeight: "bolder" }}>{`$${user.feesQuantity ? user.feesQuantity * 250 : 0} NZD`}</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            {getDivider()}
                            <div className="py-5 text-center">
                                <button
                                    disabled={isDisable}
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>

                            <div className="text-center">
                                <h3>
                                    For More Information

                                </h3>
                                <div>
                                    <span> Kiranbhai - <a href="tel:+64220158346">(+64220158346)</a> </span> <br />
                                    <span>Niravbhai - <a href="tel:+642040389108">(+642040389108)</a></span>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div >
        </div >
    </>
}

export default PersonalDetails