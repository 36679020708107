import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";

export function NavigationBar() {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand className="px-3">Hari Prabodham</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link className="nav-link" to={ROUTER_URL_CONSTANT.DASHBOARD}>
              Dashboard
            </Link>
            <Link className="nav-link" to={ROUTER_URL_CONSTANT.ABOUT}>
              About
            </Link>
            <Link className="nav-link" to={ROUTER_URL_CONSTANT.USERLIST}>
              UserList
            </Link>
            <Link className="nav-link" to={ROUTER_URL_CONSTANT.TEXTBOXDEMO}>
              Text Box Demo
            </Link>
          </Nav>
          <Nav className="pr-5">
            <NavDropdown
              title={`Jemin ${
                userListState.loader ? "loading.." : userListState.data.length
              } `}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item>
                <Link className="nav-link" to={ROUTER_URL_CONSTANT.LOGIN}>
                  Logout
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> */}
      </Navbar>
    </>
  );
}
