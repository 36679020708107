import { useEffect, useState } from "react";

import { CSVLink, CSVDownload } from "react-csv";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import parivarShibirService from "./parivarShibir.service";
import Accordion from 'react-bootstrap/Accordion';
const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
export function UserList() {
    const [userDetails, setuserDetails] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        getGuestList();
    }, []);

    const convertToDate = (date: any, isTime?:boolean) => {
        if (date) {
            let date1 = `${date
                .toDate()
                .toLocaleDateString('en-GB', options)} `
        if(isTime){
            date1 = date1 + `${date
                .toDate()
                .toLocaleTimeString("en-US")}`
        }
            console.log(date1)
            return date1
        }
        else return ""
    }

    const getGuestList = () => {

        console.log("finaldata");

        parivarShibirService.getAll()
            .collection("/parivarShibir")
            // .orderBy("createDate", "desc")
            .get()
            .then((res) => {
                let arr: any = [];
                let csvdata: any = [];
                res.docs.sort()
                res.docs.map((doc) => {
                    let finaldata = doc.data();
                    if (!finaldata.isDeleted) {
                        finaldata.id = doc.id;
                        arr.push(finaldata.userData);
                        delete finaldata.id;
                        delete finaldata.isDeleted;
                        if (finaldata.userData.arrivingDateTime) {
                            finaldata.userData.arrivingDateTime = convertToDate(finaldata.userData.arrivingDateTime, true)
                        }
                        if (finaldata.userData.departerDateTime) {
                            finaldata.userData.departerDateTime = convertToDate(finaldata.userData.departerDateTime, true)
                        }
                        if (finaldata.userData.dob) {
                            finaldata.userData.dob = convertToDate(finaldata.userData.dob)
                        }
                        if (finaldata.userData.createdDate) {
                            finaldata.userData.createdDate = convertToDate(finaldata.userData.createdDate, true)
                        }
                        finaldata.userData.familyDetails.map((res: any) => {
                            res.userDob = convertToDate(res.userDob)
                        })
                        finaldata.userData.family = finaldata.userData.familyDetails.map((res: any,index:number) => {
                             let data =[]
                             if(index == 0) {
                                data.push(`${res.userFirstName} - ${res.userDob} ${res.userContactNo ? `- ${res.userContactNo}` :""} `)
                             } else
                              data.push(`\n ${res.userFirstName} - ${res.userDob} ${res.userContactNo ? `- ${res.userContactNo}` :""} `)
                            return data
                        })
                        finaldata.userData.family = finaldata.userData.family.toString()
                        finaldata.userData.totalFamilyMember = finaldata.userData.familyDetails.length
                        csvdata.push(finaldata.userData);
                    }
                });
                arr = JSON.parse(JSON.stringify(arr))
                setuserDetails(arr);
                csvdata = csvdata.map((res: any) => {
                    delete res.familyDetails
                    return res
                })
                console.log(csvdata)
                setCsvData(csvdata);
            })
            .catch(function (error) {
                console.error("Error writing Value: ", error);
            });
    };

    const deleteRecord = (data: any) => {
        confirmAlert({
            message: `Are you sure to delete record of ${data.guestFirstName} ${data.guestlastName}.`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        data.isDeleted = true;
                        let id = data.id;
                        delete data.id;
                        parivarShibirService.update(id)
                            .update(data)
                            .then((res) => {
                                console.log(res);
                                getGuestList();
                                toast.success("Record deleted successfully..");
                            });
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    return (
        <>
            <div className="py-2 d-flex justify-content-between">
                <div>
                    <h4>Guest List</h4>
                </div>

                <div>
                    <CSVLink data={csvData}>Export Data</CSVLink>
                </div>
            </div>
            <div className="d-flex py-2">
                <h5>Total - ({userDetails.length})</h5>

            </div>
            <Accordion>
                {userDetails.map((res: any, index) => {
                    console.log(res)
                    return <>
                        <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header>
                                <div className="d-flex flex-wrap" style={{ width: "85%", alignItems: "center" }}>


                                    <div className="mx-2 my-2"> <b>{`${res.firstName} ${res.lastName}`}</b></div>

                                    <div className="mx-2 my-2"> {`${res.streetAddress}, ${res.city}`}</div>

                                    <div className="mx-2 my-2"> <b>Transport {res.requiredTransport == "Yes" ? "Required" : "Not Required"}</b></div>
                                    <div>

                                        <a href={`tel:${res.phoneNo}`}>{res.phoneNo} </a>
                                    </div>
                                    <div className="mx-2 my-2">

                                        {res.arrivingBy == "ByFlight" &&
                                            <div style={{
                                                fontSize: 44,
                                                color: "#6779ef"
                                            }}>🛬</div>
                                        }
                                        {res.arrivingBy == "ByCar" &&

                                            <div style={{
                                                fontSize: 44,
                                                color: "#6779ef"
                                            }}>🚗</div>}
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="table-responsive-lg table-responsive-md table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Dob</th>
                                                <th scope="col">Contact No</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {res.familyDetails.map((res1: any) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <b>{res1.userFirstName}</b>
                                                            </td>
                                                            <td>
                                                                <b>{res1?.userDob}</b>
                                                            </td>
                                                            <td>
                                                                <b><a href={`tel:${res1.userContactNo}`}> {res1.userContactNo}</a></b>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </>
                })}
            </Accordion>



        </>
    );
}
