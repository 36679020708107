import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Home } from './home';
function App() {
  return (
    <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={"/app"} />}
      />
      <Route path={"/app"} component={Home} />
    </Switch>
  </Router>
  );
}

export default App;
